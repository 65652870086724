import React, { useState, useRef, useEffect } from "react";
import {
  Select,
  Alert,
  Button,
  Card,
  Form,
  Input,
  Spin,
  Checkbox,
  Popover,
  Collapse,
  Modal,
} from "antd";
import CheckIcon from "../../assets/svgs/check.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Section from "../../components/Section";
import { EyeShowIcon, EyeHideIcon } from "../../components/Icons";
import PrimaryButton from "../../components/PrimaryButton";
import { Auth } from "aws-amplify";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useAuth } from "../../providers/auth.context";
import { signup } from "../../APIClient";
import moment from "moment";
import StateFromZip from "../../hooks/StateFromZip";
import CustomDatePicker from "../../components/CustomDatePicker";
import DavidImage from "../../assets/images/David.png";
import ReasonWhy from "../../assets/images/3reason-why.png";

// App constants
import { AUTH_USER_TOKEN_KEY, USER_PROFILE } from "../../utils";
import { getProfile } from "../../APIClient";

const { Panel } = Collapse;

const Register = () => {
  const [error, setError] = useState("");
  const [values, setValues] = useState({
    email: "",
    full_name: "",
    date_of_birth: "",
    password: "",
    zip_code: "",
    no_insurance_plan: "",
    agree: "",
  });
  const [loading, setLoading] = useState(false);
  const [referentLoaded, setReferentLoaded] = useState(false);
  const [initialName, setInitialName] = useState("");
  const [initialMail, setInitialMail] = useState("");
  const [nudged, setNudged] = useState(false);

  const history = useHistory();
  const { registeringUser, updateRegisteringUser, updateCurrentUser } = useAuth();
  const dobOnChange = useRef("");
  const [, forceUpdate] = useState();
  const [loadingStartEvaluation, setLoadingStartEvaluation] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);


  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      element: (message) => (
        <div className="rnm-input-error text-xs">{message}</div>
      ),
      validators: {
        full_name: {
          message: "First name and Last name must be specified",
          rule: function (val, params) {
            return val.toString().trim().split(" ").length >= 2;
          },
        },
        date_older_than: {
          message: "You must be older than 18 years-old",
          rule: function (val, params) {
            dobOnChange.current = val;
            return (
              moment(val).diff(moment().subtract(params[0], "years"), "hours") <
              0
            );
          },
        },
        stateZip: {
          message: "Invalid zip code",
          rule: function (val, params) {
            const state = StateFromZip(val.toString().trim());
            return params.includes(state.code);
          },
        },
      },
    })
  );
  useEffect(() => {
    localStorage.removeItem(USER_PROFILE);
  }, []);

  useEffect(() => {
    let url = null;

    try {
      url = new URL(window.location.href)
    } catch (e){}

    let params = new URLSearchParams(url.search);
    let paramReferent = params.get("r");
    let referent = null
    try{
      referent = JSON.parse(atob(paramReferent))
      if(referent){
        //setInitialMail(referent.nudged_email)
        //setInitialName(referent.nudged_name)
        handleChange({target: {name: "email", value: referent.nudged_email}})
        handleChange({target: {name: "full_name", value: referent.nudged_name}})

        //fixme: backend base64 reference in url
        //is returning nudger_name as nudger_email
        setNudged(referent.nudger_email)
      }

    } catch (e){}
    
    setReferentLoaded(true);    
    
  }, []);
  useEffect(() => {}, [values]);
  const disabled = () => !simpleValidator.current.allValid();

  const onSubmit = async () => {
    setLoading(true);
    setError("");

    if (!values.email.trim() && !values.password.trim()) {
      setError("Incorrect username or password");
      return;
    }

    try {
      const { user } = await Auth.signUp({
        username: values.email.trim(),
        password: values.password.trim(),
        attributes: {
          email: values.email.trim(),
          'custom:autoConfirm': nudged ? '1' : '0',
        },
      });
      const profile = await signup({
        email: values.email.trim(),
        full_name: values.full_name.trim(),
        date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
        zip_code: values.zip_code.trim(),
        no_insurance_plan: values.no_insurance_plan,
        //nudge: null,
        nudge: nudged,
      });
      updateRegisteringUser({
        username: values.email.trim(),
        password: values.password.trim(),
      });

      if(nudged){
        sessionStorage.setItem("email_ccode", values.email.trim());
        setLoading(false);
        history.push({
          pathname: "/confirmation-code",
          state: {
            alreadyVerify: true
          }
        });
      } else {
        sessionStorage.setItem("email_ccode", values.email.trim());
        setLoading(false);
        history.push("/confirmation-code");
      }
    } catch (e) {
      setError(e.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setValues((v) => ({ ...v, [name]: checked }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    simpleValidator.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: value }));
  };

  const handleDatePickerChange = (value) => {
    simpleValidator.current.showMessageFor("date_of_birth");
    setValues((v) => ({ ...v, date_of_birth: value === null ? "" : value }));
  };

  const handleDatePickerBlur = (value) => {
    simpleValidator.current.showMessageFor("date_of_birth");
    setValues((v) => ({ ...v, date_of_birth: value === null ? "" : value }));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalStart = async (path = "/onboarding/survey") => {
    setLoadingStartEvaluation(true);
    try {
      const user = await Auth.signIn(
        registeringUser.username,
        registeringUser.password
      );

      if (user) {
        localStorage.setItem(
          AUTH_USER_TOKEN_KEY,
          user.signInUserSession.accessToken.jwtToken
        );

        const profile = await getProfile();
        localStorage.setItem(USER_PROFILE, JSON.stringify(profile.Item));
        updateRegisteringUser({});
        updateCurrentUser({
          email: user.username,
          username: user.username,
          token: user.storage.accessToken,
          is_onboarded: false,
        });
      }

      history.push(path);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Section className="h-full pb-0 flex justify-center">
      <div
        className="max-w-screen-xl w-full flex items-center"
        data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="0"
      >
        <div className="flex justify-around flex-col md:flex-row w-full gap-32 mt-10">
          <div className="mt-6">
            <h3 className="text-3xl md:text-6xl font-bold leading-none mb-[120px]">
              Welcome <br /> to Renuma!
            </h3>
            <div className="flex justify-end mt-0 mr-8">
              <img width={947} src={ReasonWhy} alt="" />
            </div>
            {/*<Collapse
              ghost
              expandIconPosition="right"
              className="collapse-register"
            >
              <Panel
                header={
                  <p className="text-base md:text-xl mb-2 font-semibold">
                    Why do I need to create an account?{" "}
                    <InfoCircleOutlined
                      style={{
                        fontSize: "14px",
                        verticalAlign: "0.205em",
                        marginLeft: "5px",
                      }}
                    />
                  </p>
                }
                key="1"
                forceRender={true}
                showArrow={false}
              >
                <p className="font-body text-sm md:text-base leading-6 text-justify">
                  Creating an account is free with no strings attached. After
                  creating an account, you can take a free sleep apnea risk
                  assessment and an automated report will be emailed to you. At
                  the end of the survey, you may be given the option to purchase
                  a sleep study or schedule a telemedicine appointment with a
                  sleep doctor, but you can also choose to do nothing further
                  and end the survey so you can share the free risk report with
                  whomever you please.
                </p>
                <div className="flex flex-row justify-center mb-8 md:mb-0">
                  <img src={DavidImage} className="w-80" />
                </div>
              </Panel>
            </Collapse>*/}
          </div>
          
          <div className="w-full md:max-w-lg">
          <div className="ml-4">
            <h3 className="text-lg font-semibold mt-5 mb-0">Why do I need to create an account?</h3>
            <p className="font-body text-sm md:text-base leading-6 text-justify">
              Creating an account allows you to view your evaluation results, easily make appointments with a 
              sleep doctor and view your order history. It also helps us to offer you the best sleep apnea care possible.
            </p>
          </div>
            <Card className="-mx-6 md:mx-0 border-none mt-[140px] mb-24 card-form">
              <h3 className="text-lg font-semibold text-renuma-violet-primary text-center mt-5 mb-0">
                CREATE AN ACCOUNT
              </h3>

              {error && (
                <Alert
                  message={error}
                  type="error"
                  style={{ marginBottom: 24 }}
                  closable
                  onClose={() => setError("")}
                />
              )}
              <Form className="mt-6 ml-7 mr-7 mb-24 md:mb-2">
                {referentLoaded && <Form.Item>
                  <div className="text-base font-body">Email</div>
                  <Input
                    name="email"
                    size="large"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="rnm-input"
                    //defaultValue={initialMail}
                    value={values.email}
                  />
                  {simpleValidator.current.message(
                    "email",
                    values.email,
                    "required|email"
                  )}
                </Form.Item>}
               {referentLoaded && <Form.Item>
                  <div className="text-base font-body">Full Name</div>
                  <Input
                    name="full_name"
                    size="large"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="rnm-input"
                    //defaultValue={initialName}
                    value={values.full_name}
                  />
                  {simpleValidator.current.message(
                    "full_name",
                    values.full_name,
                    "required|full_name|min:3|max:100"
                  )}
                </Form.Item>}
                <Form.Item>
                  <div className="text-base font-body">
                    Date of birth (MM/DD/YYYY)
                  </div>
                  <CustomDatePicker
                    onChange={handleDatePickerChange}
                    onClickOutside={handleDatePickerBlur}
                    selected={values.date_of_birth}
                    maxDate={
                      new Date(
                        moment()
                          .subtract(18, "years")
                          .format("MM/DD/YYYY hh:mm:ss")
                      )
                    }
                    showYearDropdown={true}
                    showMonthDropdown={true}
                  />
                  {simpleValidator.current.message(
                    "date_of_birth",
                    values.date_of_birth,
                    "required|date_older_than:18"
                  )}
                </Form.Item>
                <Form.Item>
                  <div className="text-base font-body">
                    Password (8 or more characters)
                  </div>
                  <Input.Password
                    name="password"
                    size="large"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="rnm-input"
                    iconRender={(visible) =>
                      visible ? (
                        <EyeHideIcon style={{ color: "#6C009E" }} />
                      ) : (
                        <EyeShowIcon style={{ color: "#6C009E" }} />
                      )
                    }
                  />
                  {simpleValidator.current.message(
                    "password",
                    values.password,
                    "required|min:8|max:20"
                  )}
                </Form.Item>
                <Form.Item>
                  <div className="text-base font-body">Zip code</div>
                  <Input
                    name="zip_code"
                    size="large"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="rnm-input"
                    inputMode="numeric"
                  />
                  <p className="text-xs mb-0 p-1 leading-none">
                    For the meantime Renuma is only operating in{" "}
                    <Popover
                      content={ 
                        <div className="max-w-xs text-xs">
                          Arizona, Arkansas, Colorado, Connecticut, Deleware, Florida, Georgia,
                          Kentucky, Maryland, Minnesota, Mississippi, Nevada,
                          New Hampshire, New Jersey, New York, North Dakota, Ohio,
                          Oklahoma, Pennsylvania, South Dakota, Tennessee and Texas
                        </div>
                      }
                      title={false}
                      trigger="click"
                    >
                      <Button
                        type="text"
                        className="p-0 m-0 text-renuma-violet-primary text-xs leading-snug h-auto"
                      >
                        22 states
                      </Button>
                    </Popover>{" "}
                  </p>
                  {simpleValidator.current.message(
                    "zip_code",
                    values.zip_code,
                    "required|stateZip:AR,AZ,CO,CT,DE,FL,GA,KY,MD,MN,MS,ND,NJ,NY,NH,NV,OH,OK,PA,SD,TN,TX"
                  )}
                </Form.Item>
                <Form.Item className="mt-6">
                  <div className="flex ">
                    <Checkbox
                      name="no_insurance_plan"
                      onChange={handleCheckboxChange}
                      className="text-xs font-base leading-none"
                    />
                    <div className="text-xs font-base leading-none ml-4">
                      I understand that if I am enrolled in any Medicare or
                      Medicaid health insurance plan I will have limited access
                      to Renuma’s services (why is this statement here?{" "}
                      <Popover
                        content={
                          <div className="max-w-xs text-xs">
                            Some of the services offered via Renuma's affiliates
                            could be considered Medicare-covered services.
                            Individuals enrolled in Medicare or Medicaid
                            (including privately managed Medicare/Medicaid)
                            cannot be offered to pay out-of-pocket for a service
                            that is covered by Medicare. Since our services are
                            out-of-pocket, we are only offering
                            Medicare/Medicaid enrollees the ability to take the
                            free Symptoms of OSA Survey. A telemedicine visit
                            with a sleep doctor, a home sleep study, and
                            enrollment in Renuma + will not be available.
                          </div>
                        }
                        title={false}
                        trigger="click"
                      >
                        <Button
                          type="text"
                          className="p-0 m-0 text-renuma-violet-primary text-xs leading-snug h-auto"
                        >
                          click here
                        </Button>
                      </Popover>{" "}
                      for more information)
                    </div>
                  </div>
                  {simpleValidator.current.message(
                    "no_insurance_plan",
                    values.no_insurance_plan ? "si" : "",
                    "required|string"
                  )}
                </Form.Item>
                <Form.Item className="mt-6">
                  <div className="flex ">
                    <Checkbox
                      name="agree"
                      onChange={handleCheckboxChange}
                      className="text-xs font-base leading-none"
                    />
                    <div className="text-xs font-base leading-none ml-4">
                      By creating an account, I agree to accept the{" "}
                      <Link
                        to="/privacy-notice"
                        className="text-renuma-violet-primary"
                        target="_blank"
                      >
                        Privacy Notice
                      </Link>{" "}
                      and the{" "}
                      <Link
                        to="/conditions-of-use"
                        className="text-renuma-violet-primary"
                        target="_blank"
                      >
                        Terms and Conditions of Use
                      </Link>
                    </div>
                  </div>
                  {simpleValidator.current.message(
                    "agree",
                    values.agree ? "si" : "",
                    "required|string"
                  )}
                </Form.Item>
                <Form.Item className="mt-6 mb-2">
                  <PrimaryButton
                    disabled={disabled() || loading}
                    onClick={onSubmit}
                    className="w-full"
                  >
                    {loading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ color: "#FFFFFF" }} />
                        }
                      />
                    ) : (
                      "Create Account"
                    )}
                  </PrimaryButton>
                </Form.Item>

                <div className="text-center text-base font-body">
                  Already have an account?
                  <Button
                    size="large"
                    type="link"
                    onClick={() => history.push("/login")}
                    className="font-body p-0 ml-2 text-renuma-violet-primary"
                  >
                    Log in
                  </Button>
                </div>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </Section>
  );
};
export default Register;
